
import axios from 'axios';
let environment = process.env.NODE_ENV;
console.log(environment,"appmodeee")
// const auth = axios.create({ baseURL: 'http://localhost:5000' });
// const api = axios.create({ baseURL: 'http://localhost:5000' });
const auth = axios.create({ baseURL: 'https://wprepairacc.tpvai.com/api' });
const api = axios.create({ baseURL: 'https://wprepairacc.tpvai.com/api' });
// const auth = axios.create({ baseURL: 'https://woodpecker-mnt.tpvai.com/api/v1' });
// const api = axios.create({ baseURL: 'https://woodpecker-mnt.tpvai.com/api/v1' });
api.interceptors.request.use(
    (request) => {
         // request.headers.Authorization = `fromccmtm`;
         return request;
    },
    (err) => {
       return Promise.reject(err);
    }
 );
 export {auth,api};