import React, { useContext, useEffect, useState } from "react";
import CircularProgress from '@mui/material/CircularProgress';
import TabContext from "../TabContext";

function WoInfotipData({ infotipData, endIndex, loading, currentPage, itemsPerPage }) {
  const [filteredData, setFilteredData] = useState([]);
  const { openTab } = useContext(TabContext);

  useEffect(() => {
    const workshop = localStorage.getItem('workshop');
    const asp = localStorage.getItem('ASC');
    const usertype = localStorage.getItem('usertype');

    const filtered = infotipData.filter(workOrder => {
      const workshopMatches = !workshop || workOrder.WorkshopName === workshop;
      const aspMatchesTPVTechnician = !workOrder.ASC || workOrder.ASC === '';
      const aspMatchesASCLeader = workOrder.ASC === asp;

      if (usertype === 'TPV Technician') {
        return workshopMatches && aspMatchesTPVTechnician;
      } else if (usertype === 'ASC Leader' || usertype === 'ASC Technician') {
        return aspMatchesASCLeader;
      } else {
        return true; // Other user types see all work orders
      }
    });

    setFilteredData(filtered.slice(0, endIndex));
  }, [infotipData, endIndex]);

  function openTabAndChangeColor(workOrderNumber) {
    document.getElementById(workOrderNumber).style.color = "blue";
    openTab(workOrderNumber);
  }

  return (
    <div className="wo-infotip">
      <h6>WO from Infotip</h6>
      {loading ? (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "100%", margin: "10px" }}>
          <CircularProgress />
        </div>
      ) : filteredData.length > 0 ? (
        <table>
          <thead>
            <tr className="table-header-row">
              <th>Seq#</th>
              <th>WO Date & Time</th>
              <th>Infotip WO number</th>
              <th>WP WO Number</th>
              <th>Handling Type</th>
              <th>Product Name</th>
              <th>Serial Number</th>
              <th>PostCode Country</th>
              <th>Workshop</th>
              <th>ASC</th>
              <th>Infotip Action Code Status</th>
              <th>Woodpecker Status</th>
              <th>Creator Role</th>
              <th>Type of Order</th>
              <th>RR</th>
              <th>TAT</th>
            </tr>
            <tr>
              <td colSpan="15">
                <hr />
              </td>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((workOrder, i) => {
              const pageSeq = (currentPage - 1) * itemsPerPage;
              return (
                <tr className="table-body-row" key={i}>
                  <td>{pageSeq + i + 1}</td>
                  <td>{workOrder.OrderDate}</td>
                  <td className="uline curPo" onClick={() => openTabAndChangeColor(`${workOrder.OrderName}`)} id={`${workOrder.OrderName}`}>{workOrder.OrderName}</td>
                  <td className="uline"></td>
                  <td>{workOrder.HandlingType}</td>
                  <td>{workOrder.ProductName}</td>
                  <td>{workOrder.SerialNumber}</td>
                  <td>{workOrder.CountryId}</td>
                  <td>{workOrder.WorkshopName}</td>
                  <td>{workOrder.ASC}</td>
                  <td className={typeof workOrder.OrderState === 'string' ? (workOrder.OrderState === "96" ? "green-status" : "red-status") : workOrder.OrderState}>{workOrder.OrderState}</td>
                  <td></td>
                  <td>{workOrder.CreatorRole}</td>
                  <td>{workOrder.TypeOfOrder}</td>
                  <td>{workOrder.RR}</td>
                  <td>{workOrder.TAT}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <div style={{ textAlign: "center", marginTop: "20px" }}>
          No data available
        </div>
      )}
      <hr style={{ color: "rgb(60, 60, 255)" }}></hr>
    </div>
  );
}

export default WoInfotipData;
