import React from 'react';
import Modal from 'react-modal';

const customStyles = {
  overlay: {
    backgroundColor: 'transparent',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    width: '400px',
    height: '130px',
    border: '1px solid #ccc',
    margin: '0 auto',
    borderRadius: '5px',
    padding: '20px',
    boxShadow: '0px 5px 15px -5px rgba(0, 0, 0, 0.5)',
  }
};

Modal.setAppElement('#root');

const ConfirmationModal = ({ isOpen, message, onConfirm, onCancel }) => {
  return (
    <Modal
      isOpen={isOpen}
      style={customStyles}
      onRequestClose={onCancel}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={false}
      contentLabel="Confirmation Modal"
    >
      <h6>{message}</h6>
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
        <button style={{ cursor: 'pointer', fontWeight: 'bold', marginRight: '10px', backgroundColor: '#007bff', color: '#fff', border: 'none', padding: '10px 20px', borderRadius: '5px' }} onClick={onConfirm}>Confirm</button>
        <button style={{ cursor: 'pointer', fontWeight: 'bold', border: 'none', padding: '10px 20px', borderRadius: '5px' }} onClick={onCancel}>Cancel</button>
      </div>
    </Modal>
  );
};

export default ConfirmationModal;
