import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import CircularProgress from '@mui/material/CircularProgress';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import Options from "./Options";
import ConfirmationModal from './ConfirmationModal';
import axios from "axios";
import { api } from '../axios/AxiosInstances';
import ImageModal from "./ImageModal";



function WoInfo({ detailedInfo, setDetailedData }) {
  const [detailedData, setLocalDetailedData] = useState(detailedInfo);
  const [loading, setLoading] = useState(false)
  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [isOpen4, setIsOpen4] = useState(false);
  const [isOpen5, setIsOpen5] = useState(false);

  const [toggleNewModelName, setToggleNewModelName] = useState(false);
  const [toggleNewOrderType, setToggleNewOrderType] = useState(false);
  const [toggleNewSerialNumber, setToggleNewSerialNumber] = useState(false);
  const [toggleNewDateOfPurchase, setToggleNewDateOfPurchase] = useState(false);
  const [toggleNewHandlingType, setToggleNewHandlingType] = useState(false);

  const [newModelName, setNewModelName] = useState("");
  const [newOrderType, setNewOrderType] = useState("");
  const [newSerialNumber, setNewSerialNumber] = useState("");
  const [newHandlingType, setNewHandlingType] = useState("");
  const [newDateOfPurchase, setNewDateOfPurchase] = useState("");

  const [updateType, setUpdateType] = useState("")
  const [updatedValue, setUpdatedValue] = useState("")
  const [orderIssueNr, setOrderIssueNr] = useState(null);
  const [changeHistory, setchangeHistory] = useState(false);
  const [actionHistory, setActionHistory] = useState(false);
  const [historyResult, setHistoryResult] = useState(() => {
    // Initialize historyResult with history data from detailedInfo
    return (detailedInfo.history || []).map((data, i) => {
      const dataArray = data.split('|');
      const obj = {};
      const fieldsToExtract = ['ChgDate', 'Action', 'ChgName', 'OrderState', 'CreateDate', 'Comments'];

      fieldsToExtract.forEach((field) => {
        if (dataArray.includes(field)) {
          obj[field] = dataArray[dataArray.indexOf(field) + 1];
        }
      });

      return obj;
    });
  });
  const [attachmentResult, setAttachmentResult] = useState(() => {
    // Initialize attachmentResult with attachment data from detailedInfo
    return (detailedInfo.attachments || []).map((data, i) => {
      const dataArray = data.split('|');
      const obj = {};
      const fieldsToExtract = ['BlgType', 'BlgArt', 'BlgDesc', 'BlgDate', 'BlgSize', 'BlgContent'];

      fieldsToExtract.forEach((field) => {
        if (dataArray.includes(field)) {
          obj[field] = dataArray[dataArray.indexOf(field) + 1];
        }
      });

      return obj;
    });
  });
  const [modalIsOpen, setModalIsOpen] = useState(false);
  // const [selectedImage, setSelectedImage] = useState('');
  const [selectedType, setSelectedType] = useState('');
  const [selectedContent, setSelectedContent] = useState('');

  const openModal = (fileType, fileContent) => {
    // setSelectedImage(imageUrl);
    setSelectedType(fileType);
    setSelectedContent(fileContent);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    // setSelectedImage('');
    setSelectedType('');
    setSelectedContent('');
  };

  const handleChangeHistory = () => {
    setchangeHistory(!changeHistory)
  }

  const handleActionHistory = () => {
    setActionHistory(!actionHistory)
  }

  const handleNewModelName = () => {
    setToggleNewModelName(true)
  }

  const handleNewOrderType = () => {
    setToggleNewOrderType(true)
  }

  const handleNewSerialNumber = () => {
    setToggleNewSerialNumber(true)
  }

  const handleNewHandlingType = () => {
    setToggleNewHandlingType(true)
  }

  const handleNewDateOfPurchase = () => {
    setToggleNewDateOfPurchase(true)
  }

  const handleAlert1 = () => {
    if (newModelName) {
      setIsOpen1(true)
    } else {
      alert("Model Name is Empty")
    }
  }

  const handleAlert2 = () => {
    if (newSerialNumber) {
      setIsOpen2(true)
    } else {
      alert("Serial Number is Empty")
    }
  }

  const handleAlert3 = () => {
    if (newOrderType) {
      setIsOpen3(true)
    } else {
      alert("Order Type is Empty")
    }
  }

  const handleAlert4 = () => {
    if (newDateOfPurchase) {
      setIsOpen4(true)
    } else {
      alert("Purchase Date is Empty")
    }
  }

  const handleAlert5 = () => {
    if (newHandlingType) {
      setIsOpen5(true)
    } else {
      alert("Handling Type is Empty")
    }
  }

  const usertype = localStorage.getItem('usertype');

  useEffect(() => {
    const WoNum = detailedData.OrderName;
    if (orderIssueNr !== null) {
      const fetchData = async () => {
        try {
          setLoading(true);
          // Fetch updated data
          const updateResponse = await api.post("/updateInfotip", {
            OrderIssueNr: orderIssueNr,
            updatedValue,
            updateType
          });
          const updateStatus = updateResponse.data.split('|')[2].trim();
          console.log(updateStatus);

          if (updateStatus === "0") {
            // console.log("updated the value");
            try {
            

              const refreshResponse = await api.post("/getPythonData", { WoNum });
              console.log(refreshResponse,"after update handlee")
              setDetailedData(refreshResponse.data);
              setLocalDetailedData(refreshResponse.data[WoNum]);

              const history = (refreshResponse.data[WoNum].history).map((data, i) => {
                const dataArray = data.split('|');
                const obj = {};
                const fieldsToExtract = ['ChgDate', 'Action', 'ChgName', 'OrderState', 'CreateDate', 'Comments'];

                fieldsToExtract.forEach((field) => {
                  if (dataArray.includes(field)) {
                    obj[field] = dataArray[dataArray.indexOf(field) + 1];
                  }
                });

                return obj;
              });
              // Update history state with fetched data
              setHistoryResult(history);

              const attach = (refreshResponse.data[WoNum].attachments).map((data, i) => {
                const dataArray = data.split('|');
                const obj = {};
                const fieldsToExtract = ['BlgType', 'BlgArt', 'BlgDesc', 'BlgDate', 'BlgSize', 'BlgContent'];

                fieldsToExtract.forEach((field) => {
                  if (dataArray.includes(field)) {
                    obj[field] = dataArray[dataArray.indexOf(field) + 1];
                  }
                });

                return obj;
              });
              // Update attachments state with fetched data
              setAttachmentResult(attach);
            } catch (err) {
              console.log("Error getting updated data: ", err);
            }
          } else {
            console.log("No update in the value");
          }
        } catch (error) {
          console.error('There was an error updating the infotip!', error);
        } finally {
          setLoading(false);
        }
      };

      fetchData();
    }
  }, [orderIssueNr, updatedValue, updateType, detailedData.OrderName, setDetailedData]);


  const handleConfirm1 = (OrderIssueNr) => {
    setIsOpen1(false);
    setToggleNewModelName(false)
    setUpdateType("ProductName")
    setUpdatedValue(newModelName)
    setOrderIssueNr(OrderIssueNr)
  };

  const handleConfirm2 = (OrderIssueNr) => {
    setIsOpen2(false);
    setToggleNewSerialNumber(false)
    setUpdateType("SerialNumber")
    setUpdatedValue(newSerialNumber)
    setOrderIssueNr(OrderIssueNr)
  };

  const handleConfirm5 = (OrderIssueNr) => {
    console.log("handling")
    setIsOpen5(false);
    setToggleNewHandlingType(false)
    setUpdateType("HandlingType")
    setUpdatedValue(newHandlingType)
    setOrderIssueNr(OrderIssueNr)
  };

  const handleConfirm3 = (OrderIssueNr) => {
    setIsOpen3(false);
    setToggleNewOrderType(false)
    setUpdateType("TypeOfOrder")
    setUpdatedValue(newOrderType)
    setOrderIssueNr(OrderIssueNr)
  };

  const handleConfirm4 = (OrderIssueNr) => {
    setIsOpen4(false);
    setToggleNewDateOfPurchase(false)
    setUpdateType("SalesDate")
    setUpdatedValue(newDateOfPurchase)
    setOrderIssueNr(OrderIssueNr)
  };

  const handleCancel = (e) => {
    setIsOpen1(false);
    setIsOpen2(false);
    setIsOpen3(false);
    setIsOpen4(false);
    setIsOpen5(false);
  };

  const getErrorDesc = () => {
    let error = ""
    detailedData.ErrorDescription.split('\\n').forEach(element => {
      error = element
      if (element.indexOf("https") === 0) {
        error = element
      }
    })
    return error
  }
  const errorLink = getErrorDesc()
   console.log(detailedData ,"detaileddata")

  return (

    <div>
      {loading ?
        (<Box sx={{ display: 'flex', height: "70vh", justifyContent: "center", padding: "5%" }}>
          <CircularProgress />
        </Box>) : <>
          <div className="deatiled-info-list">
            {detailedData.OrderName && <span>Infotip Workorder Number :   {detailedData.OrderName}</span>}
            <ul>
              <li className="listItemStyle">
                <span className="labelStyle">Workorder date:</span>{" "}
                {typeof detailedData.OrderDate === "string"
                  ? detailedData.OrderDate.split(" ")[0]
                  : detailedData.OrderDate}
              </li>
              <li className="listItemStyle">
                <span className="labelStyle">Country:</span>{" "}
                {detailedData.CountryId}
              </li>
              <li className="listItemStyle">
                <span className="labelStyle">Workshop name:</span>{" "}
                {detailedData.WorkshopName}
              </li>
              <li className="listItemStyle">
                <span className="labelStyle">Status:</span>{" "}
                {detailedData.OrderState}
              </li>
              <li className="listItemStyle">
                <span className="labelStyle">Model name:</span>{" "}
                <div style={{ display: "flex" }}>
                  {detailedData.ProductName}
                  {usertype !== 'Manager' && (
                    <>
                      <button style={{ margin: '0 40px' }} className="update curPo" onClick={handleNewModelName}>
                        Update
                      </button>
                      {toggleNewModelName && (
                        <div>
                          <span>New Model Name:</span>
                          <input
                            className="select-styled"
                            type="text"
                            value={newModelName}
                            onChange={(e) => setNewModelName(e.target.value)}
                          />
                          <button type="button" className="curPo confirm" onClick={handleAlert1}>
                            Update to Infotip
                          </button>
                          <ConfirmationModal
                            isOpen={isOpen1}
                            message="Update Model Name to Infotip?"
                            onConfirm={() => handleConfirm1(detailedData.OrderIssueNr)}
                            onCancel={handleCancel}
                          />
                          <button
                            type="button"
                            className="curPo cancel"
                            style={{ marginLeft: '10px' }}
                            onClick={() => {
                              setNewModelName(''); // Clear input value
                              setToggleNewModelName(false); // Hide the update section
                            }}
                          >
                            Cancel
                          </button>
                        </div>
                      )}
                    </>
                  )}
                </div>

              </li>
              <li className="listItemStyle">
                <span className="labelStyle">Serial Number:</span>{" "}
                {<div style={{ display: "flex" }}>
                  {detailedData.SerialNumber}
                  {usertype !== 'Manager' && (
                    <>
                  <button style={{ margin: '0 40px' }} className="update curPo" onClick={handleNewSerialNumber}>Update</button>
                  {toggleNewSerialNumber && <div>
                    <span>New Serial Number:</span>
                    <input className="select-styled" type="text" value={newSerialNumber} onChange={(e) => setNewSerialNumber(e.target.value)}></input>
                    <button type='button' className="curPo confirm" onClick={handleAlert2}>Update to Infotip</button>
                    <ConfirmationModal
                      isOpen={isOpen2}
                      message="Update Serial Number to Infotip?"
                      onConfirm={() => handleConfirm2(detailedData.OrderIssueNr)}
                      onCancel={handleCancel}
                    />
                        <button
                          type="button"
                          className="curPo cancel"
                          style={{ marginLeft: '10px' }}
                          onClick={() => {
                            setNewSerialNumber(''); // Clear input value
                            setToggleNewSerialNumber(false); // Hide the update section
                          }}
                        >
                          Cancel
                        </button>
                  </div>}</>
                  )}
                </div>}
              </li>
              <li className="listItemStyle">
                <span className="labelStyle">Creator role:</span>{" "}
                {detailedData.CreatorRole}
              </li>
              <li className="listItemStyle">
                <span className="labelStyle">Handling type:</span>{" "}
                {<div style={{ display: "flex" }}>
                  {detailedData.HandlingType}
                  {/* {usertype !== 'Manager' && (
                    <>
                      <button style={{ margin: '0 40px' }} className="update curPo" onClick={handleNewHandlingType}>
                        Update
                      </button>
                      {toggleNewHandlingType && (
                        <div>
                          <span>New Handling Type:</span>
                          <input
                            className="select-styled"
                            type="text"
                            value={newHandlingType}
                            onChange={(e) => setNewHandlingType(e.target.value)}
                          />
                          <button type="button" className="curPo confirm" onClick={handleAlert5}>
                            Update to Infotip
                          </button>
                          <ConfirmationModal
                            isOpen={isOpen5}
                            message="Update Handling Type to Infotip?"
                            onConfirm={() => handleConfirm5(detailedData.OrderIssueNr)}
                            onCancel={handleCancel}
                          />
                            <button
                            type="button"
                            className="curPo cancel"
                            style={{ marginLeft: '10px' }}
                            onClick={() => {
                              setNewHandlingType(''); // Clear input value
                              setToggleNewHandlingType(false); // Hide the update section
                            }}
                          >
                            Cancel
                          </button>
                        </div>
                      )}
                    </>
                  )} */}
                </div>}
              </li>
              <li className="listItemStyle">
                <span className="labelStyle">Date of purchase:</span>{" "}
                {<div style={{ display: "flex" }}>
                  {detailedData.SalesDate}
                  {usertype !== 'Manager' && (
                    <>
                  <button style={{ margin: '0 40px' }} className="update curPo" onClick={handleNewDateOfPurchase}>Update</button>
                  {toggleNewDateOfPurchase && <div>
                    <span>New Purchase Date:</span>
                    <input className="select-styled"  placeholder="dd.mm.yyyy" type="text" value={newDateOfPurchase} onChange={(e) => setNewDateOfPurchase(e.target.value)}></input>
                    <button type='button' className="curPo confirm" onClick={handleAlert4}>Update to Infotip</button>
                    <ConfirmationModal
                      isOpen={isOpen4}
                      message="Update Purchase Date to Infotip?"
                      onConfirm={() => handleConfirm4(detailedData.OrderIssueNr)}
                      onCancel={handleCancel}
                    />
                      <button
                            type="button"
                            className="curPo cancel"
                            style={{ marginLeft: '10px' }}
                            onClick={() => {
                              setNewDateOfPurchase(''); // Clear input value
                              setToggleNewDateOfPurchase(false); // Hide the update section
                            }}
                          >
                            Cancel
                          </button>
                  </div>}
                  </>)}
                </div>}
              </li>
              <li className="listItemStyle">
                <span className="labelStyle">Type of order:</span>{" "}
                {<div style={{ display: "flex" }}>
                  {detailedData.TypeOfOrder}
                  {usertype !== 'Manager' && (
                    <>
                  <button style={{ margin: '0 40px' }} className="update curPo" onClick={handleNewOrderType}>Update</button>
                  {toggleNewOrderType && <div>
                    <span>New type of order:</span>
                    <select className="select-styled" value={newOrderType} onChange={(e) => setNewOrderType(e.target.value)}>
                          <option value={""}>-select-</option>
                          <option value={"guarantee"}>In Warranty (guarantee)</option>
                          <option value={"repair"}>Out of Warranty (repair)</option>
                          <option value={"doa"}>DOA (doa)</option>
                          <option value={"extguarantee"}>Extended Warranty (extguarantee)</option>
                          <option value={"goodwill"}>Payable by TPV (goodwill)</option>
                    </select>
                    <button type='button' className="curPo confirm" onClick={handleAlert3}>Update to Infotip</button>
                    <ConfirmationModal
                      isOpen={isOpen3}
                      message="Update OrderType to Infotip?"
                      onConfirm={() => handleConfirm3(detailedData.OrderIssueNr)}
                      onCancel={handleCancel}
                    />
                      <button
                            type="button"
                            className="curPo cancel"
                            style={{ marginLeft: '10px' }}
                            onClick={() => {
                              setNewOrderType(''); // Clear input value
                              setToggleNewOrderType(false); // Hide the update section
                            }}
                          >
                            Cancel
                          </button>
                     
                  </div>}
                  </>)}
                </div>}
              </li>
              <li className="listItemStyle">
                <span className="labelStyle">Error description:</span>{" "}
                {/* <a href={getErrorDesc()} target="_blank" rel="noreferrer">{getErrorDesc()}</a> */}
                {errorLink.indexOf("https") === 0 ?
                  <a href={errorLink} target="_blank" rel="noreferrer" >{errorLink}</a> :
                  detailedData.ErrorDescription}
              </li>
              <li className="listItemStyle">
                <span className="labelStyle">Wrap up first level:</span>{" "}
                {detailedData.Wrapup1}
              </li>
              <li className="listItemStyle">
                <span className="labelStyle">Wrap-up second level:</span>{" "}
                {detailedData.Wrapup2}
              </li>
              <li className="listItemStyle">
                <span className="labelStyle">Wrap up owner:</span>{" "}
                {detailedData.WrapupOwner}
              </li>
              <li className="listItemStyle">
                <span className="labelStyle">Wrap up category:</span>{" "}
                {detailedData.WrapupCategory}
              </li>
              <li className="listItemStyle">
                <span className="labelStyle">Repeat repair?:</span>{" "}
                {detailedData.RepeatRepair}
              </li>
              <li className="listItemStyle" style={{ alignItems: 'flex-start' }}>
                <span className="labelStyle">Available attachment:</span>{" "}
                {attachmentResult.length > 0 ? <table className="available-attachment-table">
                  <thead>
                    <tr>
                      <th><TextSnippetOutlinedIcon fontSize="small"></TextSnippetOutlinedIcon></th>
                      <th>File type</th>
                      <th>Description</th>
                      <th>Document date</th>
                      <th>File size</th>
                    </tr>
                  </thead>
                  <tbody>
                    {attachmentResult.map((data, i) => {
                      return (
                        <tr key={i}>
                          <td><TextSnippetOutlinedIcon style={{ cursor: "pointer" }} fontSize="small" onClick={() => openModal((data.BlgType).split('.')[1], data.BlgContent)}></TextSnippetOutlinedIcon></td>
                          <ImageModal
                            isOpen={modalIsOpen}
                            onRequestClose={closeModal}
                            fileContent={selectedContent}
                            fileType={selectedType}
                          />
                          <td>{data.BlgType}</td>
                          <td>{data.BlgArt}:{data.BlgDesc}</td>
                          <td>{data.BlgDate}</td>
                          <td>{parseInt((data.BlgSize) / 1024)} KByte</td>
                        </tr>)
                    })}
                  </tbody>
                </table> : <span>No Attachments</span>}
              </li>
              <li className="listItemStyle">
                <span className="labelStyle">Type of packaging:</span>{" "}
                {detailedData.TypeOfPackaging}
              </li>
              <li className="listItemStyle">
                <span className="labelStyle">Preferred service date:</span>{" "}
                {detailedData.HomeServiceServiceDate}
              </li>

              <br></br>
              <li className="listItemStyle" style={{ alignItems: 'flex-start' }}>
                <span style={{ display: 'flex', alignItems: 'center' }}>
                  <input className='checkbox-styled' type='checkbox' onChange={handleChangeHistory} /><label>Change History</label>
                </span>{" "}
                {changeHistory && <table className="available-attachment-table" style={{ width: '35%' }}>
                  <thead>
                    <tr>
                      <th>Status</th>
                      <th>Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {historyResult.map((data, i) => {
                      return (
                        <tr key={i}>
                          <td>{data.OrderState}</td>
                          <td>{data.ChgDate.split(' ')[0]}</td>
                        </tr>)
                    })}
                  </tbody>
                </table>}
              </li>
              <br></br>
              <li className="listItemStyle" style={{ alignItems: 'flex-start' }}>
                <span style={{ display: 'flex', alignItems: 'center' }}>
                  <input className='checkbox-styled' type='checkbox' onChange={handleActionHistory} /><label>Action History</label>
                </span>{" "}
                {actionHistory &&
                  <div>
                    <table className="available-attachment-table">
                      <thead>
                        <tr>
                          <th>Action</th>
                          <th>Comment</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {historyResult.map((data, i) => {
                          return (
                            <tr key={i} className={i % 2 === 0 ? "blue-row" : undefined}>
                              <td>{data.Action}<br></br>{data.ChgName}</td>
                              <td>{data.Comments}</td>
                              <td>{data.CreateDate}<br></br>{data.OrderState}</td>
                            </tr>)
                        })}
                      </tbody>
                    </table>
                    <table className="available-attachment-table">
                      <thead>
                        <tr>
                          <th>Email by Eskalation</th>
                          <th>Portal</th>
                          <th>Lnd</th>
                          <th>Sp</th>
                          <th>Date</th>
                          <th>Time</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td></td>
                          <td>{detailedData.Interface}</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>}
              </li>
            </ul>
          </div>
          <Options detailedData={detailedData} />
        </>}
    </div>
  );
}

export default WoInfo;
