import axios from "axios";
import { api } from '../axios/AxiosInstances';
import React, { useRef, useState } from "react";
import { Box, CircularProgress, Backdrop } from '@mui/material';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function PickUp({ detailedData, textOptions, getPythonData }) {
  const [selectedDate, setSelectedDate] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const currentDate = new Date();
  const [ownTransportToggle, setOwnTransportToggle] = useState(true)
  const [reqWHToggle, setReqWHToggle] = useState(true)
  const fileInputRef = useRef(null);
  const [attachLoading, setAttachLoading] = useState(false)
  const [selectedValues, setSelectedValues] = useState({});

  const handleOwnTransport = () => {
    setOwnTransportToggle(false);
  }

  const handleOwnTransportCancel = () => {
    setOwnTransportToggle(true);
  }

  const handleReqWHToggle = () => {
    setReqWHToggle(false);
  }

  const handleReqWHToggleCancel = () => {
    setReqWHToggle(true);
  }

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setIsOpen(false);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const [packageCondition, setPackageCondition] = useState("");
  const [accessories, setAccessories] = useState([]);
  const [photos, setPhotos] = useState([]);

  const handlePackageConditionChange = (event) => {
    setPackageCondition(event.target.value);
  };

  const handleAccessoryChange = (event) => {
    const newAccessories = [...accessories];
    if (event.target.checked) {
      newAccessories.push(event.target.value);
    } else {
      const index = newAccessories.indexOf(event.target.value);
      newAccessories.splice(index, 1);
    }
    setAccessories(newAccessories);
    // console.log(accessories)
  };

  var expanded = false;

  const showCheckboxes = () => {
    var checkboxes = document.getElementById("checkboxes");
    if (!expanded) {
      checkboxes.style.display = "block";
      expanded = true;
    } else {
      checkboxes.style.display = "none";
      expanded = false;
    }
  }

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handlePhotoUpload = (event) => {
    const fileTypes = [".jpg", ".jpeg", ".gif", ".pdf", ".png"]
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      const fileSize = file.size / (1024 * 1024)
      const fileExtension = file.name.split('.').pop().toLowerCase();

      if (!fileTypes.includes("." + fileExtension)) {
        window.alert(`${fileExtension} file is not allowed!`)
        event.target.value = '';
        return
      }

      if (fileSize > 3) {
        window.alert("File is larger than 3MB")
        event.target.value = '';
        return;
      }
      const newPhotos = [...photos];

      const reader = new FileReader(); // FileReader is instantiated.
      reader.onloadend = () => { // onloadend handler is defined but not yet executed.
        const base64String = reader.result.split(',')[1];
        newPhotos.push({
          fileName: file.name,
          // filePath: file.path,
          dateTime: new Date().toLocaleString(),
          fileExt: fileExtension,
          content: base64String,
        });
        setPhotos(newPhotos);

        event.target.value = '';
      };
      reader.readAsDataURL(file); // readAsDataURL method starts reading the file.
    } else {
      console.log("No file selected");
    }
  };

  const handleCancel = (delFile) => {
    const newPhotos = photos.filter(photo => photo.fileName !== delFile);
    setPhotos(newPhotos);
  }

  // function getRandomUniqueNumber(min, max, existingNumbers) {
  //   let randomNumber;
  //   do {
  //     randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
  //   } while (existingNumbers.includes(randomNumber));
  //   return randomNumber..toString();
  // }

  function getUniqueIndex(arr) {
    const numberArray = arr.map(str => parseInt(str, 10));
    // const numberArray = arr.map(Number);
    const maxNumber = Math.max(...numberArray);
    return (maxNumber + 1).toString();
  }

  const handleConfirm = async (fileName, fileExtension, content, textValue) => {
    // console.log(filePath)
    setAttachLoading(true)
    try {
      const orderIssueNr = detailedData.OrderIssueNr
      const orderName = detailedData.OrderName
      const uniqueIndex = (getUniqueIndex(detailedData.docIndexes))
      // const uniqueIndex = getRandomUniqueNumber(500, 899, detailedData.docIndexes);
      const docName = `${orderName}-${uniqueIndex}.${fileExtension}`;

      const response = await axios.post("http://localhost:5000/sendAttachment", { orderIssueNr, uniqueIndex, docName, content, textValue })
      // console.log(response)
      if (response.data.split('|')[0].trim() === "0") {
        setAttachLoading(false)
        window.alert("Attachment sent to Infotip")
        handleCancel(fileName)
        getPythonData(detailedData.OrderName)
      } else {
        setAttachLoading(false)
        window.alert("Error sending the attachment")
      }
    } catch (error) {
      setAttachLoading(false)
      window.alert("Error sending the attachment")
      console.log("error sending the attachment")
    }
  }

  const handleSelectChange = (i, value) => {
    setSelectedValues(prevState => ({
      ...prevState,
      [i]: value
    }));
  };

  return (
    // <div>
    //   <div className="address">
    //     <h6>Pickup address:</h6>
    //     <div>
    //       {detailedData.pickup_address.split(",").map((val, i) => {
    //         return <p key={i}>{val}</p>;
    //       })}
    //     </div>
    //     <div>
    //       <p>Tel: {detailedData.pickup_phone}</p>
    //       <p>Email: {detailedData.pickup_email}</p>
    //     </div>
    //     <div>
    //       {isOpen && (
    //         <div className="dropdown-calendar-container">
    //           <DatePicker
    //             selected={selectedDate}
    //             onChange={handleDateChange}
    //             inline
    //             required
    //             maxDate={currentDate}
    //             onClickOutside={() => setIsOpen(false)}
    //           />
    //         </div>
    //       )}
    //     </div>
    //   </div>

    //   <div className="plan-request-date-container">
    //     <h6>Pickup:</h6>
    //     <div className="plan-request-date">
    //       <div className="plan-date">
    //         {ownTransportToggle ?
    //         <button className="curPo confirm" onClick={handleOwnTransport}>Own Transport</button> :
    //           <div style={{ display: "flex", alignItems: "baseline" }}>
    //             <p>Planned Date & Time:</p>
    //             <select></select>
    //             <button className="curPo confirm">Confirmed (351)</button>
    //             <button className="curPo" onClick={handleOwnTransportCancel}>Cancel</button>
    //             <button
    //               className="curPo confirm selected-date"
    //               onClick={toggleDropdown}
    //             >
    //               Change planned date & time
    //             </button>
    //           </div>}
    //       </div>

    //       <div>
    //         {reqWHToggle ? 
    //         <button className="curPo confirm" onClick={handleReqWHToggle}>Request WH Pickup</button> :
    //         <div style={{ display: "flex", alignItems: "baseline" }}>
    //           <p>Request Date & Time:</p>
    //           <select></select>
    //           <button className="curPo confirm">Confirm</button>
    //           <button className="curPo" onClick={handleReqWHToggleCancel}>Cancel</button>
    //         </div>}
    //       </div>
    //     </div>
    //   </div>

    //   <div>
    //     <h6>Defect TV available at GZ: </h6>
    //     <h6>
    //       Defect TV Status at arriving GZ: package condition and accessory
    //       detail
    //     </h6>
    //     <div className="package-condition">
    //       <label htmlFor="packageCondition">Package condition:</label>
    //       <select
    //         id="packageCondition"
    //         value={packageCondition}
    //         onChange={handlePackageConditionChange}
    //       >
    //         <option value="">select condition</option>
    //         <option value="good">Good</option>
    //         <option value="damaged">Damaged</option>
    //       </select>
    //       <div>
    //         <label htmlFor="comment">Comment:</label>
    //         <input id="comment"></input>
    //       </div>
    //     </div>
    //     <div className="accessories">
    //       <label>Accessories:</label>
    //       <div>
    //         <input
    //           type="checkbox"
    //           id="remote"
    //           value="remote"
    //           onChange={handleAccessoryChange}
    //         />
    //         <label htmlFor="remote">Remote</label>
    //       </div>
    //       <div>
    //         <input
    //           type="checkbox"
    //           id="stand"
    //           value="stand"
    //           onChange={handleAccessoryChange}
    //         />
    //         <label htmlFor="stand">Stand</label>
    //       </div>
    //       <div>
    //         <input
    //           type="checkbox"
    //           id="powerCable"
    //           value="powerCable"
    //           onChange={handleAccessoryChange}
    //         />
    //         <label htmlFor="powerCable">Power cable</label>
    //       </div>
    //       <div>
    //         <label htmlFor="comment">Comment:</label>
    //         <input id="comment"></input>
    //       </div>
    //     </div>
    //     <div>
    //       <label htmlFor="photoUpload">Add photo:</label>
    //       <input
    //         type="file"
    //         id="photoUpload"
    //         accept="image/*"
    //         onChange={handlePhotoUpload}
    //       />
    //     </div>
    //     {photos.length !== 0 && (
    //       <div style={{ display: "flex" }}>
    //         <h6>Photos</h6>
    //         <table className="photos-table" style={{ borderSpacing: 20 }}>
    //           <thead>
    //             <th>File Name</th>
    //             <th>Date Time</th>
    //             <th>Assign Infotip Attachment Type</th>
    //             <th>Send to Infotip</th>
    //           </thead>
    //           {photos.map((photo) => (
    //             <tbody key={photo.fileName}>
    //               <td>{photo.fileName}</td>
    //               <td>{photo.dateTime}</td>
    //               <td>
    //                 <select></select>
    //               </td>
    //               <td>
    //                 <button className="confirm curPo">Confirm</button>
    //                 <button className="curPo">Cancel</button>
    //               </td>
    //             </tbody>
    //           ))}
    //         </table>
    //       </div>
    //     )}
    //   </div>
    // </div>

    <div style={{ marginLeft: "2%" }}>
      <div className="address">
        <h6>Pickup address:</h6>
        <div>
          {/* {"1234 Elm Street, City, State, ZIP".split(",").map((val, i) => { */}
          {`${detailedData.Firstline}, ${detailedData.Street}, ${detailedData.Postcode} ${detailedData.City}, ${detailedData.CountryId}`.split(",").map((val, i) => {
            return <p key={i}>{val}</p>;
          })}
        </div>
        <div>
          <p>Tel: {detailedData.Phone}</p>
          <p>Email: {detailedData.Email}</p>
        </div>
        <div>
          {isOpen && (
            <div className="dropdown-calendar-container">
              <DatePicker
                selected={selectedDate}
                onChange={handleDateChange}
                inline
                required
                maxDate={currentDate}
                onClickOutside={() => setIsOpen(false)}
              />
            </div>
          )}
        </div>
      </div>

      <div className="plan-request-date-container">
        <h6>Pickup:</h6>
        <div className="plan-request-date">
          <div className="plan-date">
            {ownTransportToggle ?
              <button className="curPo confirm" onClick={handleOwnTransport}>Own Transport</button> :
              <div style={{ display: "flex", alignItems: "baseline" }}>
                <p>Planned Date & Time:</p>
                <select className="select-styled"></select>
                <button className="curPo confirm">Confirmed (351)</button>
                <button className="curPo cancel" onClick={handleOwnTransportCancel}>Cancel</button>
                <button
                  className="curPo confirm selected-date"
                  onClick={toggleDropdown}
                >
                  Change planned date & time
                </button>
              </div>}
          </div>

          <div>
            {reqWHToggle ?
              <button className="curPo confirm" onClick={handleReqWHToggle}>Request WH Pickup</button> :
              <div style={{ display: "flex", alignItems: "baseline" }}>
                <p>Request Date & Time:</p>
                <select className="select-styled"></select>
                <button className="curPo confirm">Confirm</button>
                <button className="curPo cancel" onClick={handleReqWHToggleCancel}>Cancel</button>
              </div>}
          </div>
        </div>
      </div>

      <div>
        <h6>Defect TV available at GZ: </h6>
        <h6>
          Defect TV Status at arriving GZ: package condition and accessory detail
        </h6>

        <div style={{ display: "grid", gridTemplateColumns: "200px 200px auto", gap: "10px", marginBottom: "10px" }}>
          <label htmlFor="packageCondition">Package condition:</label>
          <select
            className="select-styled"
            id="packageCondition"
            value={packageCondition}
            onChange={handlePackageConditionChange}
          >
            <option value="">select condition</option>
            <option value="good">Good</option>
            <option value="damaged">Damaged</option>
          </select>
          <div>
            <label htmlFor="comment">Comment:</label>
            <input className="input-styled" id="comment"></input>
          </div>
          <label>Accessories:</label>
          <div>
            <div className="selectBox" onClick={showCheckboxes}>
              <select className="select-styled">
                <option value="">Select an option</option>
              </select>
              <div className="overSelect"></div>
            </div>
            <div id="checkboxes">
              <label htmlFor="remote"><input type="checkbox" id="one" value="remote" onChange={handleAccessoryChange} />Remote</label>
              <label htmlFor="stand"><input type="checkbox" id="two" value="stand" onChange={handleAccessoryChange} />Stand</label>
              <label htmlFor="powerCable"><input type="checkbox" id="three" value="power cable" onChange={handleAccessoryChange} />Power cable</label>
            </div>
          </div>
          <div>
            <label htmlFor="comment">Comment:</label>
            <input className="input-styled" id="comment"></input>
          </div>
        </div>


        <div>
          <label htmlFor="photoUpload">Add photo:</label>
          <input
            type="file"
            id="photoUpload"
            accept=".jpg, .jpeg, .gif, .pdf, .png"
            style={{ display: 'none' }}
            ref={fileInputRef}
            onChange={handlePhotoUpload}
          />
          <button className="curPo confirm" onClick={handleButtonClick}>Choose File</button>
        </div>
        {photos.length !== 0 && (
          <div style={{ display: "flex" }}>
            <h6>Photos</h6>
            <table className="photos-table" style={{ borderSpacing: 20 }}>
              <thead>
                <tr>
                  <th>File Name</th>
                  <th>Date Time</th>
                  <th>Assign Infotip Attachment Type</th>
                  <th>Send to Infotip</th>
                </tr>
              </thead>
              <tbody>
                {photos.map((photo, i) => {
                  const textValue = selectedValues[i] || '';
                  return (
                    <tr key={i}>
                      <td>{photo.fileName}</td>
                      <td>{photo.dateTime}</td>
                      <td>
                        <select className="select-styled" style={{ marginLeft: "-5px" }} required value={textValue} onChange={(e) => handleSelectChange(i, e.target.value)}>
                          <option value="">Select</option>
                          {textOptions && Object.keys(textOptions).length > 0 && Object.entries(textOptions).map(([key, value]) => (
                            <option key={key} value={value}>{key}</option>
                          ))}
                        </select>
                      </td>
                      <td>
                        <button className="confirm curPo" type="button" onClick={() => { handleConfirm(photo.fileName, photo.fileExt, photo.content, textValue) }}>Confirm</button>
                        <button className="curPo cancel" onClick={() => handleCancel(photo.fileName)} type="button">Cancel</button>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        )}
      </div>
      {attachLoading &&
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <Box
            sx={{
              display: 'flex',
              height: '70vh',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <CircularProgress sx={{ color: 'grey.500' }} />
          </Box>
        </Backdrop>}
    </div>
  );
}

export default PickUp;
