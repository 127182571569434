import React, { useState } from "react";
import DatePicker from "react-datepicker";
import ConfirmationModal from "./ConfirmationModal";
import axios from "axios";
import { api } from '../axios/AxiosInstances';
// import "react-datepicker/dist/react-datepicker.css";

function HomeVisit({ detailedData }) {
  const [selectedDate, setSelectedDate] = useState(null);
  const [isDateTimeInputVisible, setIsDateTimeInputVisible] = useState(false);
  const [plannedDateTime, setPlannedDateTime] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen1, setIsOpen1] = useState(false);
  const currentDate = new Date();
  const [email, setEmail] = useState(detailedData.Email)
  const [mobile, setMobile] = useState(detailedData.Mobil)
  const [informByEmail, setInformByEmail] = useState(false);
  const [informByMobile, setInformByMobile] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setIsOpen(false);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleInformByEmail = (e) => {
    const checked = e.target.checked;
    setInformByEmail(checked);
    if (checked) {
      setInformByMobile(false); // Uncheck SMS checkbox
    }
  };

  const handleInformByMobile = (e) => {
    const checked = e.target.checked;
    setInformByMobile(checked);
    if (checked) {
      setInformByEmail(false); // Uncheck email checkbox
    }
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleMobileChange = (e) => {
    setMobile(e.target.value);
  };

  const handleAlert = () => {
    if (informByEmail) {
      setModalMessage("Send Mail?");
    } else if (informByMobile) {
      setModalMessage("Send SMS?");
    } else {
      setModalMessage("No option selected.");
    }
    setIsOpen1(true);
  };

  const handleConfirm1 = async (mailId) => {
    //by email
    setIsOpen1(false);
    if (informByEmail) {
      console.log(`Sending email to: ${email}`);
      const mailData = {
        // to: mailId,
        to: "asmita.khot@tpv-tech.com",
        // to :${detailedData.Email}
        subject: "Inform Customer for home visit details",
        text: `Contact Details:\nTel: ${detailedData.Phone}\nEmail: ${detailedData.Email}`,
        html: `
        <p><strong>HomeVisit Address:</strong></p>
        <p>${detailedData.Street}, ${detailedData.Postcode} ${detailedData.City}, ${detailedData.CountryId}</p>
        <p>Tel: ${detailedData.Phone}</p>
        <p>Email: ${detailedData.Email}</p>
      `,
      }
      try {
        await api.post('/sendMail', { mailData });
        // console.log(response.data);
        window.alert("Mail sent sussessfully!.")
      } catch (error) {
        console.error("error sending mail", error);
      }
    }
    //by sms
    if (informByMobile) {
      console.log(`Sending SMS to: ${mobile}`);
      window.alert("SMS sent sussessfully!.")
    }
  };

  const handleCancel = (e) => {
    setIsOpen1(false);
  };

  const toggleDateTimeInput = () => {
    setIsDateTimeInputVisible(!isDateTimeInputVisible);
  };

  const handleDateTimeChange = (event) => {
    setPlannedDateTime(event.target.value);
  };

  return (
    <div style={{ marginLeft: "2%" }}>
      <div className="address">
        <h6>Home Visit address:</h6>
        <div>
          {/* {"1234 Elm Street, City, State, ZIP".split(",").map((val, i) => { */}
          {`${detailedData.Firstline}, ${detailedData.Street}, ${detailedData.Postcode} ${detailedData.City}, ${detailedData.CountryId}`.split(",").map((val, i) => {
            return <p key={i}>{val}</p>;
          })}
        </div>
        <div>
          <p>Tel: {detailedData.Phone}</p>
          <p>Email: {detailedData.Email}</p>
        </div>
        <div>
          {isOpen && (
            <div style={{ marginLeft: "2%" }} className="dropdown-calendar-container">
              <DatePicker
                selected={selectedDate}
                onChange={handleDateChange}
                inline
                required
                maxDate={currentDate}
                onClickOutside={() => setIsOpen(false)}
              />
            </div>
          )}
        </div>
      </div>

      <div className="plan-request-date-container">
        <h6>Home Visit:</h6>
        <div>
        <div style={{ display: 'flex', alignItems: "baseline", gap: '20px' }}>
        <p>Planned Date & Time:</p>
        {/* <input type="date" className="select-styled" /> */}

        {/* Date & Time Input, only visible when 'Change planned date & time' is clicked */}
        {isDateTimeInputVisible && (
          <input
            type="date"
            className="select-styled"
            value={plannedDateTime}
            onChange={handleDateTimeChange}
          />
        )}
        
        <button className="curPo confirm">Confirmed (181)</button>
        <button className="curPo cancel">Cancel</button>
        
        {/* Toggle Button to Show/Hide Date & Time Input */}
        <button
          className="curPo confirm selected-date"
          onClick={toggleDateTimeInput}
        >
          Change planned date & time
        </button>
      </div>
          <div style={{ display: "flex", gap: '20px' }}>
            <span>Inform Customer for home visit details: </span>
            <div>
              <div style={{ display: "grid", gridTemplateColumns: "30px 70px 70px auto", marginBottom: "5px" }}>
                <input className='checkbox-styled' type='checkbox' onChange={handleInformByEmail}  checked={informByEmail}/>
                <span>by email</span>
                <span>Email:</span>
                <input style={{ boxSizing: "border-box", border: "solid 1px black" }} type="email" id="email" onChange={handleEmailChange} value={email} />
              </div>
              <div style={{ display: "grid", gridTemplateColumns: "30px 70px 70px auto" }}>
                <input className='checkbox-styled' type='checkbox' onChange={handleInformByMobile}  checked={informByMobile}/>
                <span>by SMS</span>
                <span>Mobil:</span>
                <input style={{ boxSizing: "border-box", border: "solid 1px black" }} type="tel" id="myInput" onChange={handleMobileChange} value={mobile} />
              </div>
              <button style={{ margin: '20px' }} className="curPo confirm" type="button" onClick={handleAlert}>Confirmed ( )</button>
              <ConfirmationModal
                isOpen={isOpen1}
                message={modalMessage}
                onConfirm={() => handleConfirm1()}
                onCancel={handleCancel}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeVisit;
