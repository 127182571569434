import React from "react";
import ReactDOM from "react-dom/client";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./index.css";
// import 'bootstrap/dist/css/bootstrap-utilities.min.css';
import Dashboard from "./dashboard";
import { Login } from './components/Login';
import Home from './Home';
import { Header } from './components/Header';
import { HashRouter, Routes, Route, BrowserRouter } from 'react-router-dom';
import UserRegistration from './components/user-management'
import Report from "./components/Report";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <HashRouter>
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/" element={<Home />}>
        <Route index element={<Dashboard />} />
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="user-management" element={<UserRegistration />} />
        <Route path="report" element={<Report />} />
      </Route>
    </Routes>
  </HashRouter>
);