import React from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link, useNavigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import App  from '../App'

function logout() {
  localStorage.clear();
}

export function Header() {
  const usertype = localStorage.getItem('usertype');
  const workshopname = localStorage.getItem('workshop');
  const asc = localStorage.getItem('ASC');
  const navigate = useNavigate();

  const handleWorkorderClick = () => {
    console.log("Launching app for workorder");
    // <App/>
    navigate('/dashboard'); // Replace with the correct route for your Workorder app
  };

  return (
    <>
      <Navbar >
        <Container fluid>
          <Nav className="me-auto">
            {/* <Nav.Link as="span" onClick={handleWorkorderClick}>
              Workorder
            </Nav.Link> */}
            <Nav.Link as={Link} to="/dashboard">Workorder</Nav.Link>
            <Nav.Link as={Link} to="/report">Report</Nav.Link>
            <Nav.Link as={Link} to="/troubleshooting">Troubleshooting Page</Nav.Link>
          </Nav>
          <Nav className="ml-auto">
            {usertype && (
              <Nav.Item>
                <Nav.Link as="span">{usertype}</Nav.Link>
              </Nav.Item>
            )}
            <Nav.Link as={Link} to="/login" onClick={logout}>Logout</Nav.Link>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
}
