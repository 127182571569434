import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from "react-bootstrap/Col";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Button from 'react-bootstrap/Button';
// import { login } from '../../api/AuthApi';
import { Footer } from './Footer';
import  companyLogo  from "../images/tpvisionlogolarge.png";
import axios from "axios";
import { api } from '../axios/AxiosInstances';

export function Login() {
    const position = 'absolute'
    let navigate = useNavigate();
    const { register, handleSubmit } = useForm();
    const onSubmit = async (formObject) => {
        try {
            // navigate('/dashboard')
            // var authenticateuser = await login(formObject);
            const authenticateuser =await api.post('/login', formObject);
            console.log(authenticateuser.data, "authhhhh")
                if (authenticateuser.data["auth-result"] == 'ok') {
                    localStorage.setItem('usertype', authenticateuser.data.usertype);
                    localStorage.setItem('username', authenticateuser.data.username);
                    localStorage.setItem('workshop', authenticateuser.data.workshop);
                    localStorage.setItem('ASC', authenticateuser.data.ASC);
                    navigate(authenticateuser.data.landing_url);
                } else {
                    alert("Invalid credentails, Try again!");
                }
          
        }
        catch (error) {
            alert(error.message)
        }
            
    };
    return (
        <Container>
            <br />
            <div style={{ float: "right" }}>
                <img style={{ width: '241px', height: '40px' }} src={companyLogo } alt={'Company Logo'} />
            </div>
            <br />
            <h2>Login</h2>
           
            <form  onSubmit={handleSubmit(onSubmit)}>
            <br/>
                <Row>
                    <Col>
                        <div>
                            <b>Username</b>
                        </div>
                        <input style={{ width: '30%' }} type="text" {...register("username")} />
                    </Col>
                </Row>
                <br/>
                <Row>
                    <Col >
                        <div>
                            <b>Password</b>
                        </div>
                        {/* <input style={{ width: '30%' }} type='password' {...register("password", { pattern: /(?=.*\d)(?=.*[a-z])(?=.*[@#$%]).{6,20}/ })} /> */}
                        <input style={{ width: '30%' }} type='password' {...register("password")} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <br />
                        <Button type="submit" >Login</Button>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <br />
                        <br />
                        <a href='/ccmchangepass' >Change Password</a>
                    </Col>
                </Row>
            </form>
            <Footer position={position} />
        </Container>
        
    );
}