import React from "react";

function HandlingHistory() {
    return (
        <div className="handling-history-container">
            <div>
                <h6>WO handling history</h6>
                <table className="handling-history-table">
                    <thead>
                        <tr>
                            <th>Date-Time</th>
                            <th>Action</th>
                            <th>Infotip Status</th>
                            <th>Comment</th>
                            <th>Operated by</th>
                            <th>WP Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default HandlingHistory;
