import React, { useState } from "react";
import ConfirmationModal from './ConfirmationModal';

function OrderCompletion() {
  const [serviceOrderCompleted, setServiceOrderCompleted] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const handleChange = (e) => {
    if (!serviceOrderCompleted) {
      setIsOpen(true);
    } else {
      setServiceOrderCompleted(false);
    }
  }

  const handleConfirm = () => {
    setIsOpen(false)
    setServiceOrderCompleted(true)
  };

  const handleCancel = (e) => {
    setIsOpen(false);
    setServiceOrderCompleted(false)
  };

  return (
    <div className="order-completion-container">
      <div>
        <div style={{ display: 'flex', gap: '10%' }}>
          <h6>UID return status:</h6>
          <table className='order-completion-table'>
            <thead>
              <tr>
                <th></th>
                <th>12NC</th>
                <th>RID</th>
                <th>Part's Description</th>
                <th>Return Status</th>
                <th>Return Reason</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Spare part 1</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>Spare part 2</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>

        <div style={{ display: 'flex', gap: '10%' }}>
          <h6>Handling Type:</h6>
          <table className='order-completion-table'>
            <thead>
              <tr>
                <th>Handling Type (Infotip)</th>
                <th>Service Scenario (TCA)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="order-completion-bottom">
          <h6>Consumer Service Solution:</h6>
          <select className="select-styled"></select>
        </div>

        <div className="order-completion-bottom">
          <h6>WO Service Completed?</h6>
          <input type='checkbox' checked={serviceOrderCompleted} onChange={handleChange} />
          <span>{serviceOrderCompleted ? "NO" : "YES"}</span>
          <ConfirmationModal
            isOpen={isOpen}
            message="Are you sure ?"
            onConfirm={handleConfirm}
            onCancel={handleCancel}
          />
          {/* <select
            value={serviceOrderCompleted}
            onChange={handleChange}
            name="serviceOrderCompleted"
          >
            <option value="YES">YES</option>
            <option value="NO">NO</option>
          </select>
          <div className="confirm-cancel-buttons">
            <button type="submit" className="confirm">
              Confirm (196)
            </button>
            <button type="button" className="cancel">Cancel</button>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default OrderCompletion;
