import React, { useState, useEffect } from "react";
import "./App.css";
import App from './App';  // Workorder component
import { Footer } from "./components/Footer";
import { Link } from "react-router-dom";

// Tab component
const Tab = ({ id, isActive, onClick }) => (
    <div className={`tab-panel`}>
        <span className={`${isActive ? "active-panel" : ""} curPo`} onClick={onClick}>
            {id}
        </span>
    </div>
);

function Dashboard() {
    const tabs = [{ id: "WO Overview" }];
    const [activeTab, setActiveTab] = useState("WO Overview");

    // Handle click to change active tab
    const handleTabClick = (tabId) => {
        setActiveTab(tabId);
    };

    useEffect(() => {
        // Automatically navigate to the "WO Overview" tab on initial load
        setActiveTab("WO Overview");
    }, []);

    const renderContent = (tab) => {
        switch (tab) {
            case "WO Overview":
                return <App />;  // Show Workorder
            default:
                return null;
        }
    };

    return (
        <>
           <App/>
            <Footer />
        </>
    );
}

export default Dashboard;
