import React, { useState } from 'react'
import AfterRepair from './AfterRepair';
import NSEAuthReq from './NSEAuthReq';
import RepairVerification from './RepairVerification';
import ConfirmationModal from './ConfirmationModal';

function Repair() {
    const [isOpen, setIsOpen] = useState(false);
    // const [confirmRepair, setconfirmRepair] = useState('NO');
    const [repairEditable, setRepairEditable] = useState(false);
    const [toggleAfterRepair, settoggleAfterRepair] = useState(false);
    const [toggleRepairVerification, settoggleRepairVerification] = useState(false);
    const [toggleAuthRequest, settoggleAuthRequest] = useState(false);

    const [formData, setFormData] = useState({
        mandatorySCC: '',
        ipmApplied: '',
        symptomCode: '',
        sectionCode: '',
        defectCode: '',
        repairCode: '',
        condCode: '',
        comment: '',
        sparePart: '',
        uidNo: '',
        usedQuant: '',
        secCode: '',
        locCode: '',
    });

    const handleForm = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleChange = (e) => {
        if (!repairEditable) {
            setIsOpen(true);
        } else {
            setRepairEditable(false)
        }
    }

    const handleConfirm = () => {
        setIsOpen(false)
        setRepairEditable(true)
    };

    const handleCancel = (e) => {
        setIsOpen(false);
        setRepairEditable(false)
    };

    // const handleConfirm = (e) => {
    //     e.preventDefault()
    //     confirmRepair === 'YES' && setRepairEditable(true)
    // }

    // const handleCancel = () => {
    //     setRepairEditable(false)
    //     setconfirmRepair('NO')
    // }

    const handleRepairSubmit = (e) => {
        e.preventDefault()
    }

    const handleClickAfterRepair = () => {
        settoggleAfterRepair(!toggleAfterRepair);
    }

    const handleClickRepairVerification = () => {
        settoggleRepairVerification(!toggleRepairVerification);
    }

    const handleClickAuthRequest = () => {
        settoggleAuthRequest(!toggleAuthRequest);
    }

    return (
        <div>
            <div>
                <div style={{ display: 'flex', gap: '1%', alignItems: 'center' }}>
                    <h6>Confirm to Repair</h6>
                    <input className='checkbox-styled' type='checkbox' checked={repairEditable} onChange={handleChange} />
                    <ConfirmationModal
                        isOpen={isOpen}
                        message="Are you sure ?"
                        onConfirm={handleConfirm}
                        onCancel={handleCancel}
                    />
                    {/* <div>
                        <select
                            value={confirmRepair}
                            onChange={handleChange}
                            name="confirmRepair"
                        >
                            <option value="YES">YES</option>
                            <option value="NO">NO</option>
                        </select>
                        <button type='submit' className="curPo confirm" onClick={handleConfirm}>Confirm (131)</button>
                        <button type='reset' className="curPo" onClick={handleCancel}>Cancel</button>
                    </div> */}
                </div>
                {repairEditable &&
                    <div>
                        <ul className="repair-list">
                            <li>
                                Current SW Version on Consumer TV:{" "}
                                <span>ABC.123.XYZ.001</span>
                            </li>
                            <li>
                                SW Version updated on Consumer TV:{" "}
                                <input type='text'></input>
                            </li>
                        </ul>

                        <form onSubmit={handleRepairSubmit}>
                            <br></br>
                            <ul className="repair-list">
                                <li>
                                    Mandatory SCC:{" "}
                                    <select
                                        value={formData.mandatorySCC}
                                        onChange={handleForm}
                                        required
                                    >
                                        <option value=""></option>
                                    </select>
                                </li>
                                <li>
                                    SCC or IPM applied:{" "}
                                    <select
                                        value={formData.ipmApplied}
                                        onChange={handleForm}
                                        required
                                    >
                                        <option value=""></option>
                                    </select>
                                </li>
                                <li>
                                    Symptom Code:{" "}
                                    <select
                                        value={formData.symptomCode}
                                        onChange={handleForm}
                                        required
                                    >
                                        <option value=""></option>
                                    </select>
                                </li>
                                <li>
                                    Section Code:{" "}
                                    <select
                                        value={formData.sectionCode}
                                        onChange={handleForm}
                                        required
                                    >
                                        <option value="">Select sectionCode</option>
                                        <option>AMX</option>
                                        <option>APA</option>
                                        <option>AXN</option>
                                        <option>CBT</option>
                                        <option>CLK</option>
                                        <option>CTR</option>
                                        <option>DDC</option>
                                        <option>DVD</option>
                                        <option>EXC</option>
                                        <option>FMW</option>
                                        <option>INC</option>
                                        <option>INP</option>
                                        <option>LCD</option>
                                        <option>LMP</option>
                                        <option>NIE</option>
                                        <option>REM</option>
                                        <option>SPK</option>
                                        <option>SYS</option>
                                        <option>WIR</option>
                                        <option>231</option>
                                        <option>431</option>
                                        <option>VPN</option>
                                        <option>WLS</option>
                                        <option>AOE</option>
                                        <option>AAX</option>
                                        <option>PCS</option>
                                        <option>SET</option>
                                    </select>
                                </li>
                                <li>
                                    Defect Code:{" "}
                                    <select
                                        value={formData.defectCode}
                                        onChange={handleForm}
                                        required
                                    >
                                        <option value=""></option>
                                    </select>
                                </li>
                                <li>
                                    Repair Code:{" "}
                                    <select
                                        value={formData.repairCode}
                                        onChange={handleForm}
                                        required
                                    >
                                        <option value=""></option>
                                    </select>
                                </li>
                                <li>
                                    Cond Code:{" "}
                                    <select
                                        value={formData.condCode}
                                        onChange={handleForm}
                                        required
                                    >
                                        <option value=""></option>
                                    </select>
                                </li>
                                <li className='comment-input'>
                                    Comment:{" "}
                                    <input type='text' value={formData.comment} onChange={handleForm}></input>
                                </li>
                                {/* ------------------------------------------------------------------------------ */}
                                <div style={{ marginTop: "20px" }}>
                                    <li>
                                        Spare part:{" "}
                                        <select
                                            value={formData.sparePart}
                                            onChange={handleForm}
                                            required
                                        >
                                            <option value=""></option>
                                        </select>
                                    </li>
                                    <li>
                                        Spare part UID No:{" "}
                                        <select
                                            value={formData.uidNo}
                                            onChange={handleForm}
                                            required
                                        >
                                            <option value=""></option>
                                        </select>
                                    </li>
                                    <li>
                                        Used Quantity:{" "}
                                        <select
                                            value={formData.usedQuant}
                                            onChange={handleForm}
                                            required
                                        >
                                            <option value=""></option>
                                        </select>
                                    </li>
                                    <li>
                                        Sec Code:{" "}
                                        <select
                                            value={formData.secCode}
                                            onChange={handleForm}
                                            required
                                        >
                                            <option value=""></option>
                                        </select>
                                    </li>
                                    <li>
                                        Loc Code:{" "}
                                        <select
                                            value={formData.locCode}
                                            onChange={handleForm}
                                            required
                                        >
                                            <option value=""></option>
                                        </select>
                                    </li>
                                </div>
                            </ul>
                        </form>
                    </div>}
            </div>

            <div>
                <div className='toggle-box'>
                    <button onClick={handleClickAfterRepair}>-</button>
                    <h6>After-repair Test Function List</h6>
                </div>
                {toggleAfterRepair && <AfterRepair />}
                <div className='toggle-box'>
                    <button onClick={handleClickRepairVerification}>-</button>
                    <h6>Repair Verification</h6>
                </div>
                {toggleRepairVerification && <RepairVerification />}
                <div className='toggle-box'>
                    <button onClick={handleClickAuthRequest}>-</button>
                    <h6>NSE Authorization Request</h6>
                </div>
                {toggleAuthRequest && <NSEAuthReq />}
            </div>
        </div>
    )
}

export default Repair
