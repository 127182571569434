import React from 'react'

function RepairVerification() {
    return (
        <div>
            <div style={{ display: 'flex', gap: "10%", alignItems: 'center' }}>
                <h6>Main Section Code for TV defect:</h6>
                <select className="select-styled" style={{ height: 'fit-content' }}></select>
            </div>
            <div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <input className='checkbox-styled' type='checkbox' /><h6>Homeservice Result:</h6>
                </div>
                <div style={{ display: 'flex', gap: '1%', alignItems: 'center' }}>
                    <span>Home Visited Date/Time</span>
                    <div>
                        <select className='select-styled'>
                        </select>
                        <button type='button' className="curPo confirm" >Confirmed</button>
                        <button type='reset' className="curPo cancel" >Cancel</button>
                    </div>
                </div>
                <div style={{ marginTop: '10px', display: 'flex', gap: '1%', alignItems: 'center' }}>
                    <span>Home Visited Result</span>
                    <div>
                        <select className="select-styled">
                            <option value="YES">OK</option>
                            <option value="NO">Change to PUR</option>
                        </select>
                        <button type='button' className="curPo confirm" >Confirm ( )</button>
                        <button type='reset' className="curPo cancel" >Cancel</button>
                    </div>
                </div>
            </div>

            <div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <input className='checkbox-styled' type='checkbox' /><h6>Repair (PUR) Result:</h6>
                </div>
                <div style={{ display: 'flex', gap: '1%', alignItems: 'center' }}>
                    <span>Repair finished?</span>
                    <div>
                        <select className='select-styled' >
                        </select>
                        <button type='button' className="curPo confirm" >Confirm (RF)</button>
                        <button type='reset' className="curPo cancel" >Cancel</button>
                    </div>
                </div>
                <div style={{ marginTop: '10px', display: 'flex', gap: '1%', alignItems: 'center' }}>
                    <span>After-repair test?</span>
                    <div>
                        <select className='select-styled'>
                            <option value="YES">OK</option>
                            <option value="NO">Re-open Repair</option>
                            <option value="NO">Repair not successful</option>
                        </select>
                        <button type='button' className="curPo confirm" >Confirm ( )</button>
                        <button type='reset' className="curPo cancel" >Cancel</button>
                    </div>
                    <span>Repair not successful</span>
                    <input className='input-styled' type='text'></input>
                </div>
            </div>
        </div>
    )
}

export default RepairVerification
