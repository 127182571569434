import React, { useContext, useState ,useEffect} from 'react'
import CircularProgress from '@mui/material/CircularProgress';
import TabContext from "../TabContext";
import ConfirmationModal from './ConfirmationModal';

function WoWpData({ wpData, loading }) {
  const [openModalIndex, setOpenModalIndex] = useState(null);
  const { openTab } = useContext(TabContext)
  const [filteredData, setFilteredData] = useState([]);
  const usertype = localStorage.getItem('usertype');
  // const [showAlert, setShowAlert] = useState(false)
  // const [activeRowIndex, setActiveRowIndex] = useState(null);

  // const usertype = localStorage.getItem('usertype')
  // const workshopname = localStorage.getItem('workshop')
  // const asc = localStorage.getItem('ASC')
  // console.log(usertype,workshopname,asc,"typeee")
  
  useEffect(() => {
    const workshop = localStorage.getItem('workshop');
    const asp = localStorage.getItem('ASC');
    const usertype = localStorage.getItem('usertype');

    const filtered = wpData.filter(workOrder => {
      const workshopMatches = !workshop || workOrder.Workshop === workshop;
      const aspMatchesTPVTechnician = !workOrder.ASP || workOrder.ASP === '';
      const shouldFilter = usertype === 'TPV Technician'

      // return shouldFilter ? workshopMatches && aspMatches : true;
      const aspMatchesASCLeader = workOrder.ASP === asp;

      if (usertype === 'TPV Technician') {
        return workshopMatches && aspMatchesTPVTechnician;
      } else if (usertype === 'ASC Leader' || usertype === 'ASC Technician') {
        return aspMatchesASCLeader;
      } else {
        return true; // Other user types see all work orders
      }
    });

    setFilteredData(filtered);
  }, [wpData]);

  function openTabAndChangeColor(workOrderNumber) {
    document.getElementById(workOrderNumber).style.color = "blue";
    openTab(workOrderNumber);
  }

  const handleAlert = (index) => {
    // setActiveRowIndex(index);
    // setShowAlert(true);
    setOpenModalIndex(index)
  }

  const handleConfirm = () => {
    console.log("sent to Infotip")
    setOpenModalIndex(null);
  };

  const handleCancel = (e) => {
    // setShowAlert(false);
    // setActiveRowIndex(null);
    setOpenModalIndex(null);
  };

  return (
    <div className="wo-wp">
      <h6>WO from WP</h6>
      <table>
        <thead>
          <tr className="table-header-row">
            <th>Seq#</th>
            <th>Order Date & Time</th>
            <th>WP WO Number</th>
            <th>Handling Type</th>
            <th>Product Name</th>
            <th>Serial Number</th>
            <th>PostCountry</th>
            <th>Workshop</th>
            <th>ASC</th>
            <th>Woodpecker Status</th>
            <th>Creator Role</th>
            <th>Type of Order</th>
            <th>RR</th>
            <th>Action</th>
          </tr>
          <tr>
            <td colSpan="14">
              <hr />
            </td>
          </tr>
        </thead>

    {!loading ?
        <tbody>
          {filteredData.map((workOrder, i) => {
            return (
              <tr className="table-body-row" key={workOrder._id}>
                <td>{i + 1}</td>
                <td>{workOrder.Order_Date_Time}</td>
                <td className="uline curPo" onClick={() => openTabAndChangeColor(`${workOrder.WP_WO_Number}`)} id={`${workOrder.WP_WO_Number}`}>{workOrder.WP_WO_Number}</td>
                <td>{typeof workOrder.Handling_Type === 'string' ? workOrder.Handling_Type.split(':')[0] : workOrder.Handling_Type}</td>
                <td>{workOrder.Product_Name}</td>
                <td>{workOrder.Serial_Number}</td>
                <td>{typeof workOrder.Country === 'string' ? workOrder.Country.split(' ')[0] : workOrder.Country}</td>
                <td>{workOrder.Workshop}</td>
                <td>{workOrder.ASP}</td>
                <td className={typeof workOrder.Infotip_Status === 'string' ? (workOrder.Infotip_Status.split(':')[0] === "96" ? "green-status" : "red-status") : workOrder.Infotip_Status}>{workOrder.Infotip_Status}</td>
                <td>{workOrder.Creator_Role}</td>
                <td>{workOrder.Type_of_Order}</td>
                <td>{workOrder.repeat_repair}</td>

                {/* {activeRowIndex === i && showAlert ? (
                  <td>
                    <button className="confirm curPo" type="submit" onClick={handleConfirm}>Confirm</button>
                    <button className="curPo" type="reset" onClick={handleCancel}>Cancel</button>
                  </td>
                ) : ( */}
                 {usertype != 'Manager' && (
                <td>
                  <button className="action curPo" onClick={() => handleAlert(i)}>Send to Infotip</button>
                  {openModalIndex === i && (
                    <ConfirmationModal
                      isOpen={true}
                      message="Are you sure you want to send this to Infotip?"
                      onConfirm={handleConfirm}
                      onCancel={handleCancel}
                    />
                  )}
                </td>
                 )}
                {/* )} */}
              </tr>
            );
          })}
        </tbody>
        :
        <tbody>
            <tr>
              <td colSpan={14}>
                <div style={{display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "100%", margin: "10px"}}>
                  <CircularProgress />
                </div>
              </td>
            </tr>
          </tbody>}
      </table>
    </div>
  )
}

export default WoWpData
